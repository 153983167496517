import React from "react"
import {FormattedMessage as Fm} from "react-intl"
import {useHistory} from "react-router-dom"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { 
  Typography, 
  Button, 
  ListItemIcon,
  Grid,
  Paper
} from "@material-ui/core"
import Image from "components/Image"
import {folio as data} from "./data"
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
    },
  },
  viewWrapper: {
    display: "flex",
  },
  folioItem: {
    maxHeight: "400px",
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
    height: "100%",
    // marginRight: theme.spacing(2),
    // '&:last-child': {
    //   marginRight: 0,
    // },
    "&:hover": {
      "& .folio__image": {
        transform: "scale(1.2)",
      },
      "& .folio__infoWrapper": {
        opacity: 1,
      },
    },
  },
  folioWrapper: {
    display: "flex",
    justifyContent: "space-between",
    // flexWrap: "wrap"
  },
  project: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  projectTitle: {
    marginBottom: theme.spacing(3)
  },
  image: {
    transitionDuration: ".7s",
    transform: "scale(1.0)",
    maxHeight: "400px",
    // minHeight: 400,
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      // minHeight: 600,
    },
  },
  folioInfoWrapper: {
    opacity: 0,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    display: "flex",
    transition: "opacity 0.8s",
    flexDirection: "column",
    justifyContent: "flex-end",
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 2%, #000000)",
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
  },
  folioTitle: {
    color: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  folioSubtitle: {
    color: "white",
    textTransform: "capitalize",
    margin: theme.spacing(1, 0),
  },
  grid: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // [theme.breakpoints.up('md')]: {
    //   flexDirection: 'row',
    // },
  },
}))

const FolioItem = ({classes,item,url, i}) => {
  const history = useHistory()
  return <Paper 
    className={classes.folioItem} 
    key={i} 
    data-aos="fade-up"
    data-aos-delay={i*200}
  >
    <Image
      src={item.cover}
      alt={item.title}
      className={clsx("folio__image", classes.image)}
      lazy={false}
    />
    <div className={clsx("folio__infoWrapper", classes.folioInfoWrapper)}>
      <div>
        <Typography variant="h6" className={classes.folioTitle}>
          {item.title}
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.folioSubtitle}
        >
          {item.subtitle}
        </Typography>
        <Button variant="outlined" color="primary"
          onClick={()=> window.open(url, "_blank").focus()}
        >
          <Fm id="projects.viewMore" />
        </Button>
      </div>
    </div>
  </Paper>
}

const Folio = props => {
  const { className, ...rest } = props
  const classes = useStyles()

  return (
    <div className={classes.grid}>
      {data.map((project, projectI) => (<div key={projectI} className={classes.project}>
        <Grid className={classes.projectTitle} container flexDirection="column">
          <Grid item container>
            <Typography variant="h5" gutterBottom>
              {project.company} - {project.project}
            </Typography>
          </Grid>
          <Typography
            color="textSecondary"
            variant="subtitle1"
          >
            {project.description}
          </Typography>
        </Grid>
            
        <Grid 
          container
          spacing={3}
          // className={classes.folioWrapper}
        >
          {project.views && project.views.map((item, itemI) => <Grid 
            key={itemI}
            container 
            item
            spacing={3}
            className={classes.viewWrapper}>
            {item.images.map((image, imageI)=> <Grid 
              key={imageI}
              item 
              sm={12}
              md={image.width}>
              <FolioItem 
                classes={classes} 
                url={project.url}
                item={image} 
                i={imageI} 
              />
            </Grid>)}
          </Grid>)}
        </Grid>
      </div>))}
    </div>
  )
}

Folio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
}

export default Folio
