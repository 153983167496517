import React, {useState, useMemo, useEffect} from "react"
import ReactDOM from "react-dom"
import App from "./pages/index.js"
import { Provider } from "react-redux"
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles"
import {IntlProvider, FormattedMessage, FormattedNumber} from "react-intl"
import {
  BrowserRouter as Router,
} from "react-router-dom"
import useDarkCtrl from "util/darkctrl"
import useLanCtrl from "util/lanctrl"

import resumeData from "../resume.json"
import translations from "../translations.json"
import makeTheme from "theme"
export const ResumeContext = React.createContext()
if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render")
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}



const Root = ()=> {
  const { dark, toggleDark } = useDarkCtrl()
  const { isGerman, toggleGerman } = useLanCtrl()
  const theme = useMemo(() => makeTheme(dark), [dark])
  const lan = isGerman ? "de" : "en"
  const lanTranslations = {}
  const flattenTranslations = (obj, parKey = "") => {
    Object.keys(obj).forEach((key) => {
      const currKey = parKey + (parKey.length > 0 ? "." : "") + key
      if (Array.isArray(obj[key]) || typeof obj[key] === "object") {
        flattenTranslations(obj[key], currKey)
      } else if (typeof obj[key] === "string") {
        lanTranslations[currKey] = obj[key]
      }
    })
  }
  console.log(lanTranslations)
  AOS.init({
    once: true,
  })
  useEffect(() => {
    AOS.refreshHard()
  }, [dark])
  flattenTranslations({...translations[lan]})

  console.log(lanTranslations)
  return <IntlProvider 
    key={lan}
    messages={lanTranslations}
    locale={lan} 
    defaultLocale="en"
  >
    <ResumeContext.Provider value={resumeData}>
      <Router>
        <ThemeProvider theme={theme}>
          <App 
            toggleDark={toggleDark} 
            toggleLan={toggleGerman} 
            dark={dark} 
            isGerman={isGerman} 
          />
        </ThemeProvider>
      </Router>
    </ResumeContext.Provider>
  </IntlProvider>
}
ReactDOM.render(<Root />, document.getElementById("root"))
