import React from "react"
import { Icon } from "@iconify/react"
import gatsbyIcon from "@iconify/icons-logos/gatsby"
import materialUiIcon from "@iconify/icons-logos/material-ui"

import { makeStyles } from "@material-ui/core/styles"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => {
  const dark = theme.palette.type === "dark"
  return {
    container: {
      background: dark ? "#2b2b2b" : "#f5f5f5",
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2, 2, 8),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    icon: {
      margin: theme.spacing(0, 0.5),
      verticalAlign: "text-bottom",
    },
    footerLink: {
      color: theme.palette.primary.main
    }
  }
})

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.container}>
      <Typography variant="subtitle2" component="p" color="textSecondary">
        © {new Date().getFullYear()} Julian de Mourgues
      </Typography>
      <Typography variant="subtitle2" component="p" color="textSecondary">
        Built following the <a href="https://jsonresume.org/" type="_blank" className={classes.footerLink}>jsonresume</a> initiative
      </Typography>
    </footer>
  )
}

export default Footer
