import React from "react"
import {FormattedMessage as FM} from "react-intl"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, ListItem, Divider, List, Grid } from "@material-ui/core"
import CardBase  from "../../components/CardBase"
import CheckIcon from "@material-ui/icons/Check"
const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
  },
  gridWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& > div": {
      marginBottom: theme.spacing(2)
    },
    "& > div:last-child": {
      marginBottom: 0
    }
  },
  featureItem: {
    alignItems: "flex-start"
  },
  featureCheck: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}))

/**
 * Component to display the job card
 *
 * @param {Object} props
 */
const CardPricingStandard = props => {
  const {
    parentFmIndex,
    title,
    subtitle,
    priceComponent,
    featureCheckComponent,
    features,
    cta,
    disclaimer,
    className,
    titleProps,
    subtitleProps,
    disclaimerProps,
    featureTitleProps,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <CardBase
      className={clsx("card-pricing-standard", classes.root, className)}
      align="left"
      {...rest}
    >
      <div 
        className={classes.gridWrapper}
      >
        <div>
          <div style={{minHeight: "105px"}}>
            <Typography
              variant="h5"
              gutterBottom
              className="card-pricing-standard__title"
              {...titleProps}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className="card-pricing-standard__subtitle"
                {...subtitleProps}
              >
                {subtitle}
              </Typography>
            )}
          </div>

        </div>
        <div>
          <Divider />
        </div>
        <div>
          {priceComponent}
        </div>
        {features && (
          <div style={{flex: 1}}>
            <List>
              {features.map((item, index) => (
                <ListItem
                  key={index}
                  disableGutters
                  className={classes.featureItem}
                >
                  <div
                    className={clsx(
                      "card-pricing-standard__feature-check",
                      classes.featureCheck,
                    )}
                  >
                    <CheckIcon />
                  </div>
                  <Typography
                    variant="h6"
                    className="card-pricing-standard__feature-title"
                    {...featureTitleProps}
                  >
                    {<FM id={`${parentFmIndex}.features.${index}`} />}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        <div>
          {cta}
        </div>
        {disclaimer && (
          <div
          >
            <Typography
              variant="caption"
              component="p"
              align="center"
              className="card-pricing-standard__disclaimer-title"
              {...disclaimerProps}
            >
              {disclaimer}
            </Typography>
          </div>
        )}
      </div>
    </CardBase>
  )
}

CardPricingStandard.defaultProps = {
  titleProps: {},
  subtitleProps: {},
  disclaimerProps: {},
  featureTitleProps: {},
}

CardPricingStandard.propTypes = {
  /**
   *  External classes
   */
  className: PropTypes.string,
  /**
   * Title of the pricing card
   */
  title: PropTypes.string.isRequired,
  /**
   *  Subtitle of the pricing card
   */
  subtitle: PropTypes.string,
  /**
   * The pricing component of the pricing card
   */
  priceComponent: PropTypes.node.isRequired,
  /**
   * The features check component of the pricing card
   */
  featureCheckComponent: PropTypes.node,
  /**
   * Features array of the pricing card
   */
  features: PropTypes.array,
  /**
   *  CTA button of the pricing card
   */
  cta: PropTypes.node.isRequired,
  /**
   * Diclaimer of the pricing card
   */
  disclaimer: PropTypes.string,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
  /**
   * Additional props to pass to the disclaimer Typography component
   */
  disclaimerProps: PropTypes.object,
  /**
   * Additional props to pass to the feature title Typography component
   */
  featureTitleProps: PropTypes.object,
}

export default CardPricingStandard
