import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container   
} from "@material-ui/core/"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(12)
  },
}))
const SectionContainer = ({children}) => {
  const classes = useStyles()
  return <Container 
    maxWidth="md" 
    fixed 
    className={classes.container}
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    {children}
  </Container>
}

export default SectionContainer