import React, { useCallback, useState, useMemo, useContext } from "react"
import {FormattedMessage as FM,FormattedTime, FormattedDate} from "react-intl"
import { Icon } from "@iconify/react"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import {ResumeContext} from "../../index"
import useStyles from "./styles"
import icons from "./icons"
import validate from "./validate"
import SectionTitle from "components/title"
import SectionContainer from "components/SectionContainer"
import BerlinImg from "images/berlin.png"
import RoomIcon from "@material-ui/icons/Room"
const Contact = () => {
  const {basics} = useContext(ResumeContext)
  const classes = useStyles(basics.profiles)()
  console.log(classes)
  return (<SectionContainer>
    <SectionTitle 
      title={<FM id="contact.title" />} 
      subTitle={<FM id="contact.subTitle" />}
    />
    <Grid container spacing={3}
      style={{justifyContent: "space-between"}}
    >
      <Grid item xs={12} md={5}>
        <List component="div">
          {basics.profiles.map(({ network, url, username }) => {
            const icon = network.toLowerCase()
              
            return <ListItem
              button
              key={network}
              component={Link}
              href={url}
              target="_blank"
              rel="noopener"
              className={`${classes.snsIcon} ${icon}`}
            >
              <ListItemIcon>
                <Icon icon={icons[icon]} />
              </ListItemIcon>
              <ListItemText primary={username} />
            </ListItem>
          })}
        </List>
      </Grid>
      <Hidden smDown>
        <Divider flexItem orientation="vertical" />
      </Hidden>
      <Grid 
        item 
        xs={12}
        md={5}
        style={{position: "relative", overflow: "hidden"}}>
        <div className={classes.mapWrapper}>
          <img 
            src={BerlinImg} 
            className={"map " + classes.map}
          />
          <RoomIcon
            className={"pin " + classes.pin}
          />
        </div>
      </Grid>
    </Grid>
  </SectionContainer>
  )
}

export default Contact
