const featuresDummy = Array(4).fill({})
export const pricingsHourly = [
  {
    amount: 75,
    priceSuffix: " / MO",
    isHighlighted: false,
    features: Array(2).fill({})
  },
  {
    amount: 90,
    priceSuffix: " / MO",
    isHighlighted: true,
    features: Array(2).fill({})
  },
  {
    amount: 100,
    priceSuffix: " / MO",
    isHighlighted: false,
    features: Array(2).fill({})
  },
]

export const pricingsPackages = [
  {
    amount: 2500,
    priceSuffix: " / MO",
    isHighlighted: false,
    features: Array(5).fill({})
  },
  {
    amount: 2900,
    priceSuffix: " / MO",
    isHighlighted: true,
    features: Array(4).fill({})
  },
  {
    amount: 4900,
    priceSuffix: " / MO",
    isHighlighted: false,
    features: Array(2).fill({})
  },
]