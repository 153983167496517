import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography   
} from "@material-ui/core/"
const useStyles = makeStyles(theme => ({
  titleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    // "&::after": {
    //   content: "''",
    //   borderStyle: "solid",
    //   borderWidth: "8px 8px 0 8px",
    //   borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
    //   position: "absolute",
    //   left: "calc(50% - 8px)",
    //   bottom: -8,
    // },
  },
  title: {
    position: "relative",
    // "&::before": {
    //   content: "'<'",
    //   color: theme.palette.action.focus,
    //   position: "absolute",
    //   right: "100%",
    // },
    // "&::after": {
    //   content: "' />'",
    //   whiteSpace: "pre",
    //   color: theme.palette.action.focus,
    //   position: "absolute",
    //   left: "100%",
    // },
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(3),
    maxWidth: 500,
    textAlign: "center"
  }
}))
const Title = ({title, subTitle}) => {
  const classes = useStyles()
  return <div className={classes.titleContainer}>
    <Typography variant="h4" component="h2" className={classes.title}>
      {title}
    </Typography>
    <Typography variant="subtitle2" className={classes.subTitle}>
      {subTitle}
    </Typography>
  </div>
}

export default Title