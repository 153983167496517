import facebook from "@iconify/icons-fa-brands/facebook"
import linkedin from "@iconify/icons-fa-brands/linkedin"
import github from "@iconify/icons-fa-brands/github"
import stackoverflow from "@iconify/icons-fa-brands/stack-overflow"
import medium from "@iconify/icons-fa-brands/medium"
import email from "@iconify/icons-fa/envelope"

export default {
  facebook,
  linkedin,
  github,
  stackoverflow,
  medium,
  email
}
