import React, { useCallback, useState, useMemo, useContext } from "react"
import {FormattedMessage as FM,FormattedTime, FormattedDate} from "react-intl"
import { Icon } from "@iconify/react"
import Folio from "./Folio"
import {ResumeContext} from "../../index"
import SectionTitle from "components/title"
import SectionContainer from "components/SectionContainer"

const Projects = () => {
  const {basics} = useContext(ResumeContext)
  return (<SectionContainer>
    <SectionTitle 
      title={<FM id="projects.title" />} 
      subTitle={<FM id="projects.subTitle" />}
    />
    <Folio />
  </SectionContainer>
  )
}
  
export default Projects
  