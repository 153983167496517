import React, { useCallback, useState, useContext } from "react"
import {FormattedMessage as FM} from "react-intl"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"
import FavoriteIcon from "@material-ui/icons/Favorite"
import WorkIcon from "@material-ui/icons/Work"
import {ResumeContext} from "../../index"
import useStyles from "./styles"
import icons from "./icons"
import SectionTitle from "components/title"
import SectionContainer from "components/SectionContainer"
const About = () => {
  const classes = useStyles()

  return (
    <SectionContainer>
      <SectionTitle title={<FM id="about.title" />} subtTitle={<FM id="about.subTitle" />} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" 
          >
            <FM
              id="about.body"
              values={{
                br: <br/>,
                aCertificates: <a 
                  href="https://www.credly.com/users/julian-de-mourgues/badges" 
                  target="_blank" 
                  className={classes.link}>
                  <FM id="about.certificates.title" />
                </a>
              }}
            />
          </Typography>

        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default About
