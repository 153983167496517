import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  picture: {
    bottom: "-200px",
    position: "absolute",
    zIndex: 2,
    textAlign: "center",
    right: 0,
    left: "50%",
    transform: "translate(-50%,0)",
    width: "400px",
  },
  rightSide: {
    padding: theme.spacing(0,6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  bgLayer: {
    height: "400px",
    width: "400px",
    position: "absolute",
    background: "rgb(2,0,36)",
    borderRadius: "50%",
    // borderTopLeftRadius: "50%",
    // borderTopRightRadius: "50%",
    background: theme.palette.type === "dark" 
      ? "linear-gradient(0deg, rgba(54,54,54,1) 0%, rgba(231,231,231,1) 100%)"
      : "linear-gradient(0deg, rgba(33,33,33,1) 0%, rgba(68,68,68,1) 100%)",
  },
  canvas: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100vh",
    zIndex: -1,
  },
  container: {
    width: "initial",
    minHeight: 600,
    margin: theme.spacing(16, 6),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6)
    },
  },
  divider: {
    width: "25%",
    margin: theme.spacing(4, 0),
  },
  monaco: {
    fontFamily: "monaco, Consolas, monospace",
  },
}))
