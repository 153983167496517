export const folio = [
  {
    views: [
      {
        images: [{
          width: 8,
          cover: '/CA1.PNG',
          title: "Desktop Landing Page",
          subtitle: "First Page User sees. The adobe animate svg is natively included, without an iframe. The Cookie consent component is custom-made."
        },
        {
          width: 4,
          cover: '/CA2.PNG',
          title: "Multiple Select Question",
          subtitle: "A typical multiselect question in the mobile and english variant."
        }
        ],
        title: "",
        subtitle: ""
      },
      {
        images: [{
          width: 4,
          cover: '/CA3.PNG',
          title: "Mobile Accordion Component",
          subtitle: "Custom requirement to group consecutive questions without branching in an accordion."
        },
        {
          width: 8,
          cover: '/CA6.PNG',
          title: "Desktop Video View",
          subtitle: "Showcase of one of many generic components - a video."
        }
        ],
        title: "",
        subtitle: ""
      },
      {
        images: [{
          width: 6,
          cover: '/CA5.PNG',
          title: "Desktop Result Page",
          subtitle: "Users get a variable address, "
        },
        {
          width: 6,
          cover: '/CA4.PNG',
          title: "Mobile Landing Page",
          subtitle: "First Page User sees. The adobe animate svg is natively included, without an iframe."
        }
        ],
        title: "Landing Page",
        subtitle: "First Page User sees. The adobe animate svg is natively included, without an iframe."
      }
    ],
    url: "https://cloudadvisor.protos-cloudsolutions.de/",
    company: 'PROTOS Technologie',
    project: 'CloudAdvisor',
    description: "Cloud Advisor is a lean but efficient tool to test 'cloud-readiness' of interested visitors of PROTOS' website. "
  },
  {
    views: [
      {
        images: [{
          width: 6,
          cover: "/BoschList.png",
          title: "Desktop List View",
          subtitle: "This List View gives the user a comprehensive overview of vehicles in the fleet."
        },
        {
          width: 6,
          cover: "/BoschSingle.png",
          title: "Desktop Single View",
          subtitle: "Shows a single vehicle with its data and live location on a map."
        },
        ],
        title: "Landing Page",
        subtitle: "First Page User sees. The adobe animate svg is natively included, without an iframe."
      }
    ],
    url: "https://www.calponia.com/",
    company: "BOSCH Engineering GmbH",
    project: "Calponia - Location & Routes",
    description: "One of two containerised apps i developed for the internal startup Calponia at Bosch Engineering. It is used as a fleet management platform. Vehicles communicated their location via an OBD Dongle with a central server. This in turn was made visible in this app."
  }
]

export const services = [
  {
    icon: "fas fa-laptop-code",
    title: "Web Design",
    description:
      "We design and develop beautiful, lightning fast, scalable, and marketing-focused websites tailored to grow your business.",
  },
  {
    icon: "fas fa-mobile-alt",
    title: "UI / UX Design",
    description:
      "We craft great user centered interfaces for web and mobile apps. We ensure to deliver intuitive and seamless experiences.",
  },
  {
    icon: "far fa-lightbulb",
    title: "Product Design",
    description:
      "We help you to carefully improve your digital product to deliver a great user experience, grow its user base and increase retention.",
  },
  {
    icon: "fas fa-brain",
    title: "Branding",
    description:
      "We transform businesses into brands through high quality brand identity design.",
  },
  {
    icon: "fas fa-rocket",
    title: "Optimization",
    description:
      "We help you optimize your website or mobile app to increase your conversion rates.",
  },
  {
    icon: "fas fa-cogs",
    title: "Automation",
    description:
      "We help your business gain leverage and efficiency through smart automation.",
  },
]

export const features = [
  "Affordable, scalable and performant. The perfect solution for small apps.",
  "A mid-sized solution for businesses undergoing rapid user growth.",
  "A farm of machines entirely dedicated to your company's storage needs.",
  "A mid-sized solution for businesses undergoing rapid user growth.",
]

export const gallery = [
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg",
    title: "MAD CAP",
    subtitle: "The coffee company",
    cols: 1,
  },
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg",
    title: "ADIDAS",
    subtitle: "ALL DAY I DREAM ABOUT SPORT",
    cols: 1,
  },
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg",
    title: "LARQ",
    subtitle: "BOTTLE REDESIGNED",
    cols: 1,
  },
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg",
    title: "NIKE",
    subtitle: "Just do it",
    cols: 1.5,
  },
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg",
    title: "MAD CAP",
    subtitle: "The coffee company",
    cols: 1.5,
  },
  {
    cover: "https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg",
    title: "ADIDAS",
    subtitle: "ALL DAY I DREAM ABOUT SPORT",
    cols: 3,
  },
]
