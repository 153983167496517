import React, { useEffect, useState } from "react"
import {Link} from "react-router-dom"
import {FormattedMessage as FM,FormattedTime, FormattedDate} from "react-intl"
import PropTypes from "prop-types"
import clsx from "clsx"
import SwiperComp from "react-id-swiper"
import "swiper/swiper.min.css"
import styled from "styled-components"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  useMediaQuery,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import Image from "components/Image"
import SectionHeader from "components/SectionHeader"
import Section from "components/Section"
import SectionTitle from "components/title"
import SectionContainer from "components/SectionContainer"

const Swiper = styled(SwiperComp)`
  .swiper-container {
    padding: 5px !important;
  }
`
const useStyles = makeStyles(theme => ({
  articleDate: {
    margin: theme.spacing(2, 0),
  },
  card: {
    border: 0,
    maxWidth: 300,
  },
  cardMedia: {
    height: 185,
    padding: "10px"
  },
  swiperWrapper: {
    width: "100%",
    "& .swiper-container": {
      padding: "12px !important",
    }
  },
  articleLink: {
    textDecoration: "none",
    color: "inherit"
  }
}))

const Articles = props => {
  const { data, ...rest } = props
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })
  const [mediumData, setMediumData] = useState(["dummy", "dummy", "dummy"])
  const [params, setParams] = useState({
    slidesPerView: isMd ? 3 : 1,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    }
  })
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()



  useEffect(()=>{
    const loadArticles = async ()=> {
      fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@juliandm&api_key=icahtbtrcmefjqnh6ezomatsu9yjfglfm0htwhjc"
      )
        .then(res => res.json())
        .then(response => {
          if (response.status !== "error") {
            setMediumData(response.items)
          }
          setIsLoading(false)
        })
        .catch(err => console.log(err))
    }
    if (mediumData[0] === "dummy") {
      loadArticles()
    }
  }, [])
  useEffect(()=>{
    console.log(isMd, "HALLO")
    setParams({
      ...params,
      slidesPerView: isMd ? 3 : 1,
    })
  }, [isMd])
  const articles = mediumData
    .filter(article => article.categories && article.categories.length > 0)
    .slice(0, 6)

  return (<SectionContainer
    
  >
    <SectionTitle 
      title={<FM id="articles.title" />} 
      subTitle={<FM id="articles.subTitle" />}
    />
    <div
      className={classes.swiperWrapper}
    >
      <Swiper 
        {...params}
      >
        {articles.map((item, index) => (isLoading
          ? <Skeleton variant="rect" width={210} height={118} />
          : <div
            // className={clsx('swiper-slide', classes.swiperSlide)}
            key={index}
          >
            <Card className={classes.card}
            >
              <CardMedia className={classes.cardMedia}>
                <img 
                  src={item.thumbnail} 
                  style={{width: "100%", height: "100%", objectFit: "contain"}}
                />
              </CardMedia>
              <CardContent>
                <Typography
                  variant="body1"
                  color="primary"
                  component="p"
                  className={classes.articleDate}
                >
                  <i>{item.author}</i>&nbsp;|&nbsp;
                  <i><FormattedDate
                    value={item.pubDate}
                    day="numeric"
                    month="long"
                    year="numeric"/>
                  </i>
                </Typography>
                <Typography variant="h6" color="textPrimary" component="p">
                  <a className={classes.articleLink} href={item.link} target="_blank">
                    {item.title}
                  </a>
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </Swiper>
      <div className="swiper-pagination" />   
    </div>

  </SectionContainer>)
}

Articles.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Data to be rendered
   */
  data: PropTypes.array.isRequired,
}

export default Articles
