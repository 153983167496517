import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"
import { light, dark } from "./palette"
import {cyan as primary, lightBlue as secondary} from "@material-ui/core/colors"

const makeTheme = (dark) =>
  // responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      primary: { ...primary, main: dark ? primary["A400"] : primary[500] },
      secondary: {
        ...secondary,
        main: dark ? secondary["A200"] : secondary[900],
      },
      type: dark ? "dark" : "light",
      background: {
        default: dark ? "#212121" : "#fafafa",
      },
    },
    layout: {
      contentWidth: 1236,
    },
    typography: {
      // fontFamily: 'Roboto',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  })
  // )
export default makeTheme
