import React from "react"
import Hero from "./hero"
import About from "./about"
import Experience from "./experience"
import Projects from "./projects"
import Contact from "./contact"
import Plans from "./plans"
import Articles from "./articles"
import {FormattedMessage as FM} from "react-intl"

export default [
  {
    id: "hero",
    title: <FM id="hero.title" />,
    component: <Hero />,
  },
  {
    id: "about",
    title: <FM id="about.title" />,
    component: <About />,
  },
  {
    id: "experience",
    title: <FM id="experience.title" />,
    component: <Experience />,
  },
  {
    id: "projects",
    title: <FM id="projects.title" />,
    component: <Projects />,
  },
  // {
  //   id: "plans",
  //   title: <FM id="plans.title" />,
  //   component: <Plans />,
  // },
  // {
  //   id: "articles",
  //   title: <FM id="articles.title" />,
  //   component: <Articles />,
  // },
  {
    id: "contact",
    title: <FM id="contact.title" />,
    component: <Contact />,
  },
]
