import React, {useContext} from "react"
import {useIntl, FormattedMessage,FormattedTime, FormattedDate} from "react-intl"
import { useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import {
  Typography,
  Grid,
  Hidden
} from "@material-ui/core"
import profilePic from "images/profile.png"
import useStyles from "./styles"
import {ResumeContext} from "../../index"
import SectionContainer from "components/SectionContainer"

const Hero = ({ updateTab }) => {
  const intl = useIntl()
  const {profile} = useContext(ResumeContext)
  const classes = useStyles()
  const dark = useTheme().palette.type === "dark"

  return (
    <Grid 
      container 
      // spacing={5}
      fixed
      alignItems="center"
      justifyContent="center"
      className={classes.container}
      data-aos="fade-up"
      data-aos-offset="-4000"
      data-aos-duration="1500"
      data-aos-mirror="false"
    >
      <Hidden smDown>
        <Grid item xs={6}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            className={classes.bgLayer}
          />
          <img 
            className={classes.picture}
            src={profilePic} 
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6}
        className={classes.rightSide}
      >
        <Typography
          variant="body1"
          component="p"
          className={classes.monaco}
          color="primary"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <FormattedMessage id="hero.mission" />
        </Typography>
        <Typography 
          variant="h2" 
          component="p"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="250"
        >
          {"Julian de Mourgues"}
        </Typography>
        <Typography 
          variant="h3" 
          component="p" 
          color="textSecondary"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          <FormattedMessage 
            values={{
              br: <br/>,
            }}
            id="hero.offer" 
          />
        </Typography>
        <Divider 
          className={classes.divider} 
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="750"
        />
        <a 
          href={intl.messages["plans.inquiry"]}
          style={{textDecoration: "none", color: "inherit"}}>
          <Button
            variant="contained"
            color="primary"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="750"
          >
            <FormattedMessage id="hero.hireMe" />
          </Button>
        </a>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.open("CV.docx", "_blank")}
          style={{marginLeft: "20px"}}
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <FormattedMessage id="hero.downloadCv" />
        </Button>
      </Grid>
    </Grid>
  )
}

export default Hero
