import React from "react"
import {useIntl, FormattedMessage as FM,FormattedTime, FormattedDate} from "react-intl"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery, Grid, Typography, Button, Container } from "@material-ui/core"
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab"
import Icon from "../../components/Icon"
import SectionHeader from "components/SectionHeader"
import Section from "components/Section"
import CardPricingStandard from "./CardPricingStandard"
import { pricingsHourly, pricingsPackages } from "./data"
import SectionTitle from "components/title"
import SectionContainer from "components/SectionContainer"

const useStyles = makeStyles(theme => {
  const dark = theme.palette.type === "dark"
  
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(5),
      },
    },
    sectionContainer: {
      backgroundColor: theme.palette.primary.dark,
      paddingBottom: theme.spacing(20),
      borderRadius: "0 0 100% 0",
    },
    textWhite: {
      color: "white",
    },
    fontWeightBold: {
      fontWeight: "bold",
    },
    toggleContainer: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(0, 2),
    },
    toggleButtonGroup: {
      background: "transparent",
    },
    toggleButton: {
      background: "transparent",
      border: "1px solid" + theme.palette.grey.main,
      padding: theme.spacing(1, 5),
    },
    toggleButtonActive: {
      background: theme.palette.primary.main + " !important",
    },
    toggleTitle: {
      textTransform: "capitalize",
      color: dark ? "white" : "inherit"
    },
    toggleTitleActive: {
      color: theme.palette.primary.contrastText,
    },
    pricingContainer: {
      position: "relative",
      marginTop: theme.spacing(5),
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
    cardPricing: {
      "& .countup-number__count-wrapper": {
        textAlign: "left",
        marginBottom: 0,
        fontWeight: "bold",
      },
    },
  })})

const Main = props => {
  const intl = useIntl()
  const { className, ...rest } = props
  const classes = useStyles()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const [pricingOption, setPricingOption] = React.useState("hourly")

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption)
  }

  const pricings = pricingOption === "hourly" ? pricingsHourly : pricingsPackages
  const fmIndex = `plans.${pricingOption}`
  return (
    <SectionContainer>
      <SectionTitle 
        title={<FM id="plans.title" />} 
        subTitle={<FM id="plans.subTitle" />} 
      />
      <div className={classes.toggleContainer} data-aos="fade-up">
        <ToggleButtonGroup
          value={pricingOption}
          exclusive
          onChange={handleClick}
          className={classes.toggleButtonGroup}
        >
          <ToggleButton
            value="hourly"
            className={clsx(
              classes.toggleButton,
              pricingOption === "hourly" ? classes.toggleButtonActive : {},
            )}
          >
            <Typography
              variant="subtitle1"
              className={clsx(
                classes.fontWeightBold,
                classes.textWhite,
                classes.toggleTitle,
                pricingOption === "hourly" ? classes.toggleTitleActive : {},
              )}
            >
              <FM id="plans.hourly.title" />
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="packages"
            className={clsx(
              classes.toggleButton,
              pricingOption === "packages" ? classes.toggleButtonActive : {},
            )}
          >
            <Typography
              variant="subtitle1"
              className={clsx(
                classes.fontWeightBold,
                classes.textWhite,
                classes.toggleTitle,
                pricingOption === "packages"
                  ? classes.toggleTitleActive
                  : {},
              )}
            >
              <FM id="plans.packages.title" />
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.pricingContainer}>
        <Section className={classes.sectionNoPaddingTop}>
          <Grid container spacing={isMd ? 4 : 2}>
            {pricings.map((item, index) => (
              <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                <CardPricingStandard
                  parentFmIndex={`${fmIndex}.${index}`}
                  variant="outlined"
                  withShadow={item.isHighlighted ? true : false}
                  title={<FM id={`${fmIndex}.${index}.title`} />}
                  liftUp
                  subtitle={<FM id={`${fmIndex}.${index}.subTitle`} />}
                  priceComponent={<div
                    style={{display: "flex", alignItems: "center"}}
                  >
                    <Typography variant="h3" color="textPrimary">
                        €
                      {item.amount}
                    </Typography>
                    <Typography variant="h4"
                      style={{marginLeft: "6px"}}
                    >
                      {pricingOption === "hourly" && "/h"}
                    </Typography>
                  </div>
                  }
                  features={item.features}
                  featureCheckComponent={
                    <Icon
                      fontIconClass="far fa-check-circle"
                      fontIconColor={theme.palette.primary.main}
                    />
                  }
                  cta={<a 
                    href={intl.messages["plans.inquiry"]}
                    style={{textDecoration: "none", color: "inherit"}}>
                    <Button
                      color="primary"
                      variant={item.isHighlighted ? "contained" : "outlined"}
                      fullWidth
                      size="large"
                    >
                        
                      <FM id="plans.bookThis" />

                    </Button></a>
                  }
                  disclaimer={<FM id={`${fmIndex}.${index}.disclaimer`} />}
                  className={classes.cardPricing}
                />
              </Grid>
            ))}
          </Grid>
        </Section>
      </div>
    </SectionContainer>
  )
}

Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
}

export default Main
