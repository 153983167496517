import React, { useMemo, useContext } from "react"
import { Icon } from "@iconify/react"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Tooltip from "@material-ui/core/Tooltip"
import Brightness2Icon from "@material-ui/icons/Brightness2"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import TranslateIcon from "@material-ui/icons/Translate"

import {ResumeContext} from "index"

import Tabs from "./tabs"
import icons from "sections/contact/icons"

const useStyles = (sns) =>
  makeStyles((theme) => {
    const dark = theme.palette.type === "dark"
    return {
      container: {
        width: 240,
        height: "100%",
        display: "flex",
      },
      list: {
        flexGrow: 1,
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(4),
      },
      util: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: theme.palette.action.hover,
        padding: theme.spacing(0.5),
      },
      utilContainer: {
        display: "flex",
        flexDirection: "column",
      },
      avatar: {
        width: 48,
        height: 48,
        padding: theme.spacing(1),
      },
      divider: {
        margin: theme.spacing(1, 0),
      },
      snsIcon: {
        width: 48,
        transition: `${theme.transitions.duration.shortest}ms`,
        ...(!dark &&
          sns
            .map(({ color, network }) => ({
              key: `&.${network.toLowerCase()}:hover`,
              value: { color },
            }))
            .reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {})),
      },
    }
  })

const SwipeableTemporaryDrawer = ({
  tab,
  updateTab,
  sections,
  drawer,
  toggleDrawer,
  dark,
  toggleDark,
  isGerman,
  toggleLan
}) => {

  const {basics} = useContext(ResumeContext)
  const profiles = basics.profiles
  const classes = useStyles(profiles)()

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onClose={(event) => toggleDrawer(event, false)}
      onOpen={(event) => toggleDrawer(event, true)}
    >
      <div className={classes.container}>
        <div
          className={classes.list}
          role="presentation"
          onClick={(event) => toggleDrawer(event, false)}
          onKeyDown={(event) => toggleDrawer(event, false)}
        >
          <Tabs tab={tab} updateTab={updateTab} sections={sections} vertical />
        </div>
        <div className={classes.util}>
          <div className={classes.utilContainer}>
            <Tooltip title="Dark Mode" placement="left" arrow>
              <IconButton aria-label="toggle dark mode" onClick={toggleDark}>
                {dark ? <Brightness7Icon /> : <Brightness2Icon />}
              </IconButton>
            </Tooltip>
            <Tooltip 
              placement="left"
              arrow
              title={isGerman ? "in english" : "auf Deutsch"}>
              <IconButton
                aria-label="toggle Language"
                onClick={toggleLan}
              >
                <TranslateIcon />
              </IconButton>
            </Tooltip>
            <Divider className={classes.divider} />
            {profiles.map(({ icon, id, title, url }) => (
              <Tooltip key={id} title={title} placement="left" arrow>
                <IconButton
                  href={url}
                  target="_blank"
                  rel="noopener"
                  className={`${classes.snsIcon} ${icon}`}
                >
                  <Icon icon={icons[icon]} />
                </IconButton>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  )
}

export default SwipeableTemporaryDrawer
