import React, { useState, useContext } from "react"
import {FormattedMessage as FM,FormattedTime, FormattedDate} from "react-intl"
import { Icon } from "@iconify/react"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepContent,
  Container,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import BuildIcon from "@material-ui/icons/Build"
import SchoolIcon from "@material-ui/icons/School"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import WorkIcon from "@material-ui/icons/Work"
import {ResumeContext} from "../../index"
import SectionTitle from "components/title"
import useStyles from "./styles"
import icons from "./icons"
import SectionContainer from "components/SectionContainer"

const JobStepper = ({ jobs, classes }) => {
  const [activeStep, setActiveStep] = useState(-1)

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      classes={{ root: classes.stepperRoot }}
    >
      {jobs.map((
        { name, highlights, technologies, startDate, endDate, position, id, summary, url },i) => (
        <Step
          key={startDate + endDate}
          active={(!i && activeStep === -1) || activeStep === i}
          completed={false}
          disabled={false}
          expanded={(!i && activeStep === -1) || activeStep === i}
        >
          <StepButton
            onClick={() => setActiveStep(i)}
            icon="●"
            optional={
              <Typography
                variant="body2"
                component="span"
                align="left"
                color={activeStep === i ? "textPrimary" : "textSecondary"}
                style={{ display: "block" }}
              >
                <FormattedDate
                  value={new Date(startDate)}
                  month="long"
                  year="numeric"/>
                {" - "}
                {endDate ? <FormattedDate
                  value={new Date(endDate)}
                  month="long"
                  year="numeric"/> : <FM id="time.today" /> }
              </Typography>
            }
          >
            <StepLabel
              classes={{
                label: `MuiTypography-h6 ${classes.stepLabelRoot}`,
                iconContainer: classes.stepLabelIconContainer,
              }}
              StepIconProps={{
                classes: {
                  root: activeStep === -1 && classes.stepIconRootAnime,
                },
              }}
            >
              {position} -{" "}
              <Link href={url} target="_blank" rel="noopener">
                {name}
              </Link>
            </StepLabel>
          </StepButton>
          <StepContent classes={{ root: classes.stepContentRoot }}>
            <Typography variant="body2" component="p">
              <em>{summary}</em>
            </Typography>
            <List dense>
              {highlights.map(title => (
                <ListItem>
                  <ListItemText
                    primary={"●  " + title}
                  />
                </ListItem>
              ))}
            </List>
            {technologies.map(title => (
              <Chip
                key={id}
                size="small"
                label={title}
                className={classes.chip}
              />
            ))}
          </StepContent>
        </Step>
      )
      )}
    </Stepper>
  )
}

const Experience = () => {
  const {work, education, skills} = useContext(ResumeContext)
  const [expanded, setExpanded] = React.useState()
  const classes = useStyles()
  skills.sort((a,b) => b.level - a.level)
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <SectionContainer>
      <SectionTitle 
        title={<FM id="experience.title" />} 
        subTitle={<FM id="experience.subTitle" />} 
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h6"
            component="p"
            className={classes.stepperTitle}
          >
            {/* <WorkIcon className={classes.stepperTitleIcon} color="primary" /> */}
            <FM id="experience.work" />
          </Typography>
          <Divider className={classes.divider} />
          <JobStepper jobs={work} classes={classes} />
          <Typography
            variant="h6"
            component="p"
            className={classes.stepperTitle}
          >
            {/* <SchoolIcon
              className={classes.stepperTitleIcon}
              color="secondary"
            /> */}
            <FM id="experience.education" />
          </Typography>
          <Divider className={classes.divider} />
          <Stepper
            orientation="vertical"
            classes={{ root: classes.stepperRoot }}
          >
            {education.map(
              ({ id, startDate, endDate, studyType, institution, area, gpa,courses, url, technologies }) => (
                <Step key={id} active={true} completed={false} disabled={false}>
                  <StepLabel
                    icon="●"
                    optional={
                      <Typography variant="body2" component="span" align="left">
                        <FormattedDate
                          value={new Date(startDate)}
                          month="long"
                          year="numeric"/>
                        {" - "}
                        {endDate ? <FormattedDate
                          value={new Date(endDate)}
                          month="long"
                          year="numeric"/> : "" }
                      </Typography>
                    }
                    classes={{
                      label: `MuiTypography-body1 ${classes.stepLabelRoot} ${classes.boldFW}`,
                      iconContainer: classes.stepLabelIconContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.stepIconRootAlt,
                      },
                    }}
                  >
                    {area}{" - "} {studyType} {" - "} 
                    <Link
                      href={url}
                      target="_blank"
                      rel="noopener"
                      color="primary"
                    >
                      {institution}
                    </Link>
                  </StepLabel>
                  <StepContent>
                    <List dense>
                      {courses.map(title => (
                        <ListItem>
                          <ListItemText
                            primary={"●  " + title}
                          />
                        </ListItem>
                      ))}
                    </List>
                    {technologies.map(title => (
                      <Chip
                        key={id}
                        size="small"
                        label={title}
                        className={classes.chip}
                      />
                    ))}
                  </StepContent>
                </Step>
              )
            )}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            component="p"
            className={classes.stepperTitle}
          >
            {/* <BuildIcon className={classes.stepperTitleIcon} color="disabled" /> */}
            <FM id="experience.skills" />
          </Typography>
          <Divider className={classes.divider} />
          {skills.map(({ keywords, name, level }) => (
            <div key={name} >
              <Accordion 
                className={classes.skillContainer}
                square 
                expanded={expanded === name}
                onChange={handleChange(name)}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header">
                  <Grid item xs={6}>
                    <Typography
                      variant="button"
                      component="p"
                      // className={classes.skillCategory}
                    >
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }} 
                  >
                    <Tooltip
                      title={level + "%"}
                    >
                      <LinearProgress 
                        variant="determinate"
                        value={level} 
                        style={{width: "100%"}}
                      />
                    </Tooltip>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  style={{flexWrap: "wrap"}}
                >
                  {keywords.map((title) => (
                    <Chip
                      key={title}
                      size="small"
                      label={title}
                      className={classes.chip}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default Experience
